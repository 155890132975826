import { AppFooter } from 'components/app/AppFooter';
import { AppHeader } from 'components/app/AppHeader';
import React, { useEffect } from 'react';
import styled from 'styled-components';

export const LayoutDefault: React.FC<{ children: React.ReactNode; wallet?: boolean }> = (props) => {
  return (
    <CptStyles>
      <AppHeader wallet={props.wallet} />
      {props.children}
      <AppFooter />
    </CptStyles>
  );
};

const CptStyles = styled.div`
  width: 100vw;
  position: relative;
`;
