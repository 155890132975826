import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Modal, message, notification } from 'antd';
import { IsSupportChainId, SupportChains } from 'constants/chains';
import { GlobalVar } from 'constants/constants';
import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { stateAppChain } from 'states/wallet';
import styled from 'styled-components';
import { useAccount, useChainId } from 'wagmi';
import { AppButton } from './AppButton';

export const AppHeader: React.FC<{ wallet?: boolean }> = (props) => {
  const _appChain = useSetRecoilState(stateAppChain);
  const acc = useAccount();
  const [notificationApi, notificationCtx] = notification.useNotification();
  const [messageApi, messageCtx] = message.useMessage();
  const [modalApi, modalCtx] = Modal.useModal();
  GlobalVar.notification = notificationApi;
  GlobalVar.message = messageApi;
  GlobalVar.modal = modalApi;

  useEffect(() => {
    const net = acc.chain;
    if (!net) return;
    if (!IsSupportChainId(net.id)) return;
    const target = SupportChains.find((c) => c.id === net.id);
    if (!target) return;
    _appChain(target);
  }, [acc.chain?.id]);

  return (
    <CptStyles>
      <div className="left">
        <NavLink className="logo" to="/">
          <img src="/logo.png" />
        </NavLink>
      </div>
      <div className="right">
        <div className="item" onClick={() => document.getElementById('Features')?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })}>
          Features
        </div>
        <div className="item" onClick={() => document.getElementById('Technology')?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })}>
          Technology
        </div>
        <Link className="item" to="#DOC">
          DOC
        </Link>
        <AppButton link="/zAce/" size="S100">
          PLAY
        </AppButton>
        {props.wallet && <ConnectButton />}
      </div>
      {notificationCtx}
      {messageCtx}
      {modalCtx}
    </CptStyles>
  );
};

const CptStyles = styled.div`
  box-sizing: border-box;
  width: 100vw;
  padding: 0 40px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 20;
  background-color: rgb(31 31 48 / 39%);
  backdrop-filter: blur(4px);
  > .right {
    display: flex;
    align-items: center;
    gap: 20px;
    > .item {
      color: #fff;
      text-shadow: 0px 3px 5px rgba(25, 51, 168, 1);
      font-weight: 700;
      padding: 0 32px;
      font-size: 16px;
      cursor: pointer;
      user-select: none;
    }
  }
  > .left {
    display: flex;
    align-items: center;
    > .logo {
      > img {
        height: 50px;
        display: block;
      }
    }
    > a {
      transition: all ease 0.2s;
      color: #fff;
      &:hover {
        text-decoration: underline;
        transform: scale(1.1);
        color: #eee;
      }
    }
  }
  @media screen and (max-width: 750px) {
    padding: 0 10px;
    height: 60px;
    justify-content: center;
    > .right {
      gap: 10px;
      > .item {
        padding: 0 10px;
        font-size: 14px;
      }
    }
    > .left {
      display: none;
    }
  }
`;
