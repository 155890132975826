import { LayoutDefault } from 'components/layouts/LayoutDefault';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Index1Png from 'assets/images/index/1.jpg';
import Index2Png from 'assets/images/index/2.jpg';
import Index3Png from 'assets/images/index/3.jpg';
import { ReactComponent as Index21Svg } from 'assets/images/index/2-1.svg';
import { ReactComponent as Index22Svg } from 'assets/images/index/2-2.svg';
import { ReactComponent as Index23Svg } from 'assets/images/index/2-3.svg';
import { ReactComponent as Index24Svg } from 'assets/images/index/2-4.svg';

export const PageIndex: React.FC<{}> = (props) => {
  return (
    <LayoutDefault>
      <PageIndexStyle>
        <div className="p1">
          <img src={Index1Png} />
        </div>
        <div className="p2" id="Features">
          <img src={Index2Png} />
          <div className="items">
            <div className="item">
              <Index21Svg />
              Fully On - Chain Poker Game
            </div>
            <div className="item">
              <Index22Svg />
              Use ZK Technology
            </div>
            <div className="item">
              <Index23Svg />
              Fairness
            </div>
            <div className="item">
              <Index24Svg />
              Earn More Rewards
            </div>
          </div>
        </div>
        <div className="p3" id="Technology">
          <img src={Index3Png} />
        </div>
      </PageIndexStyle>
    </LayoutDefault>
  );
};

const PageIndexStyle = styled.div`
  padding-top: 100px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  > .p2 {
    @media screen and (max-width: 1000px) {
      > img {
        min-height: 600px;
        object-fit: cover;
      }
    }
    > .items {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      flex-wrap: wrap;
      display: flex;
      width: 100vw;
      gap: 30px;
      align-items: center;
      justify-content: center;
      align-content: center;
      color: #fff;
      padding: 0 6vw;
      box-sizing: border-box;
      @media screen and (max-width: 1000px) {
        padding: 0;
        gap: 10px;
      }
      > .item {
        gap: 40px;
        min-width: 30vw;
        background: rgba(255, 255, 255, 0.1);
        width: 585px;
        height: 360px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
        font-family: Inter;
        font-size: 32px;
        font-weight: 700;
        @media screen and (max-width: 1450px) {
          width: 33vw;
          height: 260px;
          text-align: center;
          font-size: 24px;
          svg {
            width: 120px;
            height: 120px;
          }
        }
        @media screen and (max-width: 1000px) {
          width: 40vw;
          height: 200px;
          text-align: center;
          font-size: 20px;
          gap: 10px;
          svg {
            width: 80px;
            height: 80px;
          }
        }
        @media screen and (max-width: 700px) {
          width: 45vw;
          text-align: center;
          font-size: 16px;
          gap: 10px;
          svg {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }

  > .p1,
  > .p2,
  > .p3 {
    width: 100vw;
    position: relative;
    > img {
      user-select: none;
      display: block;
      width: 100vw;
    }
  }
  > .p1 {
    @media screen and (max-width: 1000px) {
      > img {
        min-height: 260px;
        object-fit: cover;
      }
    }
  }
  > .p3 {
    @media screen and (max-width: 1000px) {
      > img {
        min-height: 700px;
        object-fit: cover;
      }
    }
  }
`;
