import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as TwitterSvg } from 'assets/images/footer/twitter.svg';
import { ReactComponent as TelegramSvg } from 'assets/images/footer/telegram.svg';
import { ReactComponent as DiscordSvg } from 'assets/images/footer/discord.svg';

const year = new Date().getFullYear();

export const AppFooter: React.FC<{}> = (props) => {
  return (
    <CptStyles>
      <div className="text">
        Technology by Zypher. <div>© {year} AceQuest. All rights reserved.</div>
      </div>
      <div className="items">
        <TwitterSvg />
        <TelegramSvg />
        <DiscordSvg />
      </div>
    </CptStyles>
  );
};

const CptStyles = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  padding: 0 40px;
  > .items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    color: #fff;
  }
  > .text {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }
  @media screen and (max-width: 1000px) {
    height: 60px;
    padding: 0 10px;
    > .items {
      gap: 10px;
    }
    > .text {
      font-size: 12px;
      text-align: left;
    }
  }
`;
