import { wagmiConfig } from 'constants/wagmi';
import { atom, selector } from 'recoil';

export const stateAppChain = atom({
  key: 'stateAppChain',
  default: wagmiConfig.chains[0] as (typeof wagmiConfig.chains)[number],
});

// export const stateAppChainInfo = selector({
//   key: 'stateAppChainInfo',
//   get({ get, getCallback }) {
//     const chain = get(stateAppChain);
//     return crTestNet[chain.id];
//   },
// });
